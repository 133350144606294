@import '../../../variables';

.population-estimates-table {
  padding: 0 5px;
}
.pop-table {
  width: 99.4%;
  margin: 5px;
  padding: 0px;
  border-collapse: collapse;
  font-size: 12px;
}
.public .pop-table, .singleAnalysis .pop-table {
  width: 100%;
  border-top: 1px solid #ccc;
}

.population-estimates.export  {
  display: inline-block;
}

.pop-row {
  cursor: pointer;
}

.pop-row:hover,
.pop-row--highlighted,
.pop-row--selected {
  border: 2px solid black;
}

.pop-row--faded {
  opacity: 0.2;
}

.pop-table th {
  font-weight: bold;
  border-bottom: 0px;
  border-top: 0px;
  background-color: #eee;
  text-align: center;
  height: 14px;
  line-height: 14px;
  padding: 3px 0;
}
.pop-table th,
.pop-table td{
  border: 1px solid #999;
}

.export .pop-table th {
  max-width: 40px;
}

.pop-table .pop-row--highlighted td,
.pop-table .pop-row--selected td {
  border-top: none;
  border-bottom: none;
}

.pop-table td div,
.pop-table th div {
  padding: 2px 5px;
 // height: 15px;
}

.pop-table .pop-row--highlighted td div,
.pop-table .pop-row--selected td div {
  //height: 14px;
}

.pop-table .chronic-phase-1-col {
  background: $phase-1-chronic-fill;
}

.pop-table .chronic-phase-2-col {
  background: $phase-2-chronic-fill;
}

.pop-table .chronic-phase-3-col {
  background: $phase-3-chronic-fill;
}

.pop-table .chronic-phase-4-col {
  background: $phase-4-chronic-fill;
  color: white;
}
.pop-table .chronic-phase-5-col {
  display: none;
}

.pop-table .phase-1-col {
  background: $phase-1-fill;
}

.pop-table .phase-2-col {
  background: $phase-2-fill;
}

.pop-table .phase-3-col {
  background: $phase-3-fill;
}

.pop-table .phase-4-col {
  background: $phase-4-fill;
  color: white;
}

.pop-table .phase-5-col {
  background: $phase-5-fill;
  color: white;
}

.pop-table .phase-6-col {
  // background:repeating-linear-gradient(145deg,#640000,#640000 3px,#cc7a7a 3px,#cc7a7a 4px)!important;
  background-color:$phase-5-fill;
  color:#fff;
}

.pop-table .phase-index-plus {
  background: #eee;
}

.pop-table .pop-table__name-col {
  max-width: 250px;
  min-width: 150px;
  overflow: hidden;
  padding-left: 10px;
}

.pop-table .pop-table__area-phase-col {
  width: 37px;
  overflow: hidden;
}

.pop-table .pop-table__area-total-col {
  width: 75px;
  overflow: hidden;
  padding: 0;
}

.export .pop-table .pop-table__name-col {
  min-width: 0;
  max-width: 150px;
}

.pop-table .pop-row.level-0 .pop-table__name-col {
  padding-left: 0;
}

.pop-table__name {
  display: flex;
  justify-content: space-between;
}

.pop-table .phase-col-val {
  min-width: 30px;
}

.pop-table .phase-col-val,
.pop-table .phase-col-pct {
  text-align: right;
}

.pop-table__group {
  color: $main-blue;
}

.export .pop-table__group {
  display: none;
}

.pop-table .pop-table__group-name {
  font-weight: bold;
  padding-left: 0px;
}

.pop-table__group-text {
  margin-right: 8px;
}

.pop-table__area-text {
  margin-left: 8px;
}

.pop-tabl__sub-area {
  margin-left: 20px;
  padding-left: 15px;
  text-indent: -15px;
}

.population-estimates-table {
  width: 100%;
}
.pop-tabl__sub-area__icon {
  transform: rotate(90deg);
}

.population-estimates.export {
  width: 800px;
}

.population-estimates.export td div {
  height: 14px;
}
.population-estimates.export .pop-table td div.pop-table__group-name {
  padding: 0;
}
