@import '../../../variables';
.pts-population-estimates-table {
  padding: 5px 0px 10px;
  overflow: auto;
  position: relative;
  white-space: nowrap;  
  background-color: #fff;
}
.pts-pop-table {
  width: 100%;
  margin: 0px;
  padding: 0px;
  border-collapse: collapse;
  font-size: 12px;
}
.public .pts-pop-table {
  border-top: 1px solid #ccc;
}
.population-estimates.export  {
  display: inline-block;
}
.pop-row {
  cursor: pointer;
}
.pop-row:hover,
.pop-row--highlighted,
.pop-row--selected {
  border: 2px solid $other-blue;
}
.pop-row--faded {
  opacity: 0.2;
}
.pts-pop-table th {
  font-weight: bold;
  background-color: #eee;
  text-align: center;
  padding: 5px;
  height: 14px;
  line-height: 14px;
}
.pts-pop-table th,
.pts-pop-table td{
  border: 1px solid #999;
  z-index: 1;
}
.pts-pop-table .sticky-column {
  position: sticky;
  position: -moz-sticky;
  position: -webkit-sticky;
  background: #fff;
  z-index: 5;
}
.pts-pop-table .sticky-column:after,
.pts-pop-table .sticky-column:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}
.pts-pop-table .sticky-column:before {
  top: -1px;
  border-top: 1px solid #999;
}

.pts-pop-table .sticky-column:after {
  bottom: -1px;
  border-bottom: 1px solid #999;
}
.pts-pop-table .first-column {
  min-width: 370px;
  left: 0;
}
.pts-pop-table .second-column {
  min-width: 70px;
  left: 368px;
}
.pts-pop-table .third-column {
  min-width: 60px;
  left: 436px;
  -webkit-box-shadow: 9px -2px 5px -6px rgba(0,0,0,0.26);
  -moz-box-shadow: 9px -2px 5px -6px rgba(0,0,0,0.26);
  box-shadow: 9px -2px 5px -6px rgba(0,0,0,0.26);
}
.export .pts-pop-table th {
  max-width: 40px;
}
.pts-pop-table .pop-row--highlighted td,
.pts-pop-table .pop-row--selected td {
  border-top: none;
  border-bottom: none;
}
.pts-pop-table td div {
  padding: 1px 5px;
  font-size: 12px;
}
.pts-pop-table th.analysis-name,
.pts-pop-table td.analysis-name {
  min-width: 370px;
}
.pts-pop-table th.analysis-name-collapse {
  text-align: right;
  color: $main-blue;
  cursor: pointer;
}
.pts-pop-table th.analysis-date,
.pts-pop-table td.analysis-date {
  min-width: 70px;
  text-align: center;
}
.pts-pop-table th.analysis-period,
.pts-pop-table td.analysis-period {
  min-width: 120px;
  text-align: center;
}
.pts-pop-table th.area-phase,
.pts-pop-table td.area-phase {
  min-width: 50px;
  text-align: center;
}
.pts-pop-table th.pop-figures,
.pts-pop-table td.pop-figures {
  min-width: 60px;
  text-align: center;
}
.pts-pop-table th.percentage,
.pts-pop-table td.percentage {
  min-width: 30px;
  text-align: center;
}
.pts-pop-table th.percentage-country-pop,
.pts-pop-table td.percentage-country-pop {
  min-width: 82px;
  text-align: center;
}
.pts-pop-table .pop-row.level-0, .pts-pop-table .pop-row.level-1, .pts-pop-table .pop-row.level-2, .pts-pop-table .pop-row.level-4,  .pts-pop-table .pop-row.level-5 {
  font-weight: 700;
  padding-left: 0px;
}
.pts-pop-table .pop-row.level-4 td:first-child div, .pts-pop-table .pop-row.level-5  td:first-child div {
  text-align: right;
  font-weight: 700;
}
.pts-pop-table td.percentage-country-pop, 
.pts-pop-table td.percentage,
.pts-pop-table td.pop-figures, 
.pts-pop-table td.area-phase, 
.pts-pop-table td.analysis-period,
.pts-pop-table td.analysis-date {
  text-align: right;
}
.pts-pop-table .pop-row--highlighted td div,
.pts-pop-table .pop-row--selected td div {
}
.pts-pop-table .chronic-phase-1-col {
  background: $phase-1-chronic-fill;
}
.pts-pop-table .chronic-phase-2-col {
  background: $phase-2-chronic-fill;
}
.pts-pop-table .chronic-phase-3-col {
  background: $phase-3-chronic-fill;
}
.pts-pop-table .chronic-phase-4-col {
  background: $phase-4-chronic-fill;
  color: white;
}
.pts-pop-table .chronic-phase-5-col {
  display: none;
}
.pts-pop-table .phase-1-col {
  background: $phase-1-fill;
}
.pts-pop-table .phase-2-col {
  background: $phase-2-fill;
}
.pts-pop-table .phase-3-col {
  background: $phase-3-fill;
}
.pts-pop-table .phase-4-col {
  background: $phase-4-fill;
  color: white;
}
.pts-pop-table .phase-5-col {
  background: $phase-5-fill;
  color: white;
}
.pts-pop-table .phase-6-col {
  background:repeating-linear-gradient(145deg,#640000,#640000 3px,#cc7a7a 3px,#cc7a7a 4px)!important;
  background-color:  $phase-5-fill;
  color:#fff
}

.pts-pop-table .phase-index-plus {
  background: #eee;
}
.pts-pop-table .pop-row.level-0 .pts-pop-table__name-col {
  padding-left: 0;
}
.pts-pop-table__name {
  display: flex;
  justify-content: space-between;
}
.pts-pop-table .phase-col-val {
  min-width: 30px;
}
.pts-pop-table .phase-col-val,
.pts-pop-table .phase-col-pct {
  text-align: right;
}
.pts-pop-table__group {
  color: $main-blue;
  display: none;
}
.pts-pop-table .pop-row.level-0:hover .pts-pop-table__group, .pts-pop-table .pop-row.level-1:hover .pts-pop-table__group {
  display: inline;
}
.pts-pop-table__group-text {
  margin-right: 8px;
}
.pts-pop-table__area-text {
  margin-left: 8px;
}
.pop-tabl__sub-area {
  margin-left: 20px;
  padding-left: 15px;
  text-indent: -15px;
}
.population-estimates-table {
  width: 100%;
}
.pop-tabl__sub-area__icon {
  transform: rotate(90deg);
}
.population-estimates.export {
  width: 800px;
}
.population-estimates.export td div {
  height: 14px;
}
.population-estimates.export .pts-pop-table td div.pts-pop-table__group-name {
  padding: 0;
}
.pts-pop-table .current-projection {
  background: #333;
  color: #fff;
}
.pts-pop-table .first-projection {
 background: #666; 
  color: #fff;
}
.pts-pop-table .second-projection {
  background: #999;
  color: #fff;
}
.pts-pop-table .black-thick-border-left {
  border-left: 2px solid #333; 
}
.pts-pop-table .black-thick-border-right {
  border-right: 2px solid #333; 
}
.pts-pop-table .grey-thick-border-right {
  border-right: 2px solid #666; 
}
.pts-pop-table .light-grey-thick-border-right {
  border-right: 2px solid #999; 
}