@import './../../../variables';

.acute-phase {
  display: flex;
  padding: 10px 0;
  align-items: center;
}

.public .acute-phase {
  display: block;
  padding: 0;
  margin: 0;
}

.acute-phase-chart {
  height: 130px;
  margin: 10px 0px;
  text-align: center;
}

.acute-phase-chart--split {
  height: 130px;
}

.acute-phase__legend {
  flex: 1;
}

.content__split-cols .export.acute-phase__legend {
  max-width: 240px;
}

.donut-segment {
  cursor: pointer;
}

.donut-segment.phase1 {
  fill: $phase-1-fill;
}

.donut-segment.phase2 {
  fill: $phase-2-fill;
}

.donut-segment.phase3 {
  fill: $phase-3-fill;
}

.donut-segment.phase4 {
  fill: $phase-4-fill;
}

.donut-segment.phase5 {
  fill: $phase-5-fill;
}

.chronic .donut-segment.phase1 {
  fill: $phase-1-chronic-fill;
}

.chronic .donut-segment.phase2 {
  fill: $phase-2-chronic-fill;
}

.chronic .donut-segment.phase3 {
  fill: $phase-3-chronic-fill;
}

.chronic .donut-segment.phase4 {
  fill: $phase-4-chronic-fill;
}

.chronic .donut-segment.phase9 {
  fill: $phase-9-chronic-fill;
}

.donut-segment.phase6 {
  fill: #ffffff;
}

.donut-segment.hover {
  stroke: #000;
}

.donut-segment.zero {
  stroke: none;
}

.acute-phase .legend-table {
  flex: 1;
  font-size: 12px;
  margin-right: 10px;
}
.legend-table .header-row {
  font-size: 12px;
}

.legend-table .population-header,
.legend-table .population-data {
  text-align: right;
  margin-left: 10px;
}

.legend-table .percent-data,
.legend-table .percent {
  width: 15px;
  text-align: right;
  margin-left: 10px;
}

.legend-table .swatch {
  margin-right: 3px !important;
}

.acute-phase__graphic.export, .acute-phase__graphic.export .acute-phase-chart{
  height: 300px !important;
  width: 300px !important;
}

.acute-phase__graphic.export .acute-phase-chart {
  transform: scale(2);
  transform-origin: 0 0;
  margin: 0;
}
