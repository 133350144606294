@import './../../_variables';
.blue {
  color : $main-blue;  
}

.analyses-block {
  width: 100%;
  background-color: #fff;
}

.analyses-block__top,
.analyses-block__main {
  display: flex;
  align-items: center;
  background-color: #eee;
  padding: 0 20px;
}

.analyses-block__top {
  justify-content: space-between;
  height: 70px;
}

.analyses-block__main {
  margin-bottom: 20px;
}

.analyses-block__main--hidden {
  display: none;
}

.analyses-block__toggle-button {
  cursor: pointer;
}

.analyses-block__bottom {
  background-color: white;
  padding: 0 20px;
}

.analyses-block__bottom-inner {
  display: flex;
  justify-content: space-between;
  // border-bottom: 2px solid black;
  padding: 10px 0 0;
}

.analyses-block__bottom-inner--split {
  border-bottom: none;
  padding-bottom: 0;
}

.analyses-block__bottom-left--split,
.analyses-block__bottom-right--split{
  flex: 1;
  // border-bottom: 2px solid black;
  margin-right: 25px;
}

.analyses-block__projected-button {
  cursor: pointer;
  font-size: 14px;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background: #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  max-width: 170px;
}

.analyses-block__projected-button:hover {
  background: #BBBBBB;
}

.analyses-block__projected-button svg {
  margin-right: 10px;
}

.analyses-block__current,
.analyses-block__projected {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.analyses-block__projected {
  display: flex;
  justify-content: space-between;
}

.analyses-block__projected .analyses-block__projected-button {
}

.analyses-block__projected .analyses-block__projected-button svg {
  margin-right: 0;
}

.analyses-block__dates {
  font-size: 14px;
}

.analyses-block__header {
  font-size: 14px;
}