$analyses-menu-height: 250px;

$main-blue: #125F9A;
$menu-blue: #DAEBF8;
$other-blue: #4F8DB7;
$phase-0-fill: #ffffff;
$phase-1-fill: #CDFACD;
$phase-2-fill: #FAE61E;
$phase-3-fill: #E67800;
$phase-4-fill: #C80000;
$phase-5-fill: #640000;
$phase-9-fill: rgb(176,176,176);
$phase-0-chronic-fill: #ffffff;
$phase-1-chronic-fill: rgb(204, 255, 204);
$phase-2-chronic-fill: rgb(203, 201, 226);
$phase-3-chronic-fill: rgb(158, 154, 200);
$phase-4-chronic-fill: rgb(106, 81, 163);
$phase-9-chronic-fill: rgb(176,176,176);
$famine-likely-image: '/icons/famine-likely.png';