@import './../../../variables';

.legend-table {
  flex: 1;
  font-size: 12px;
  margin-right: 10px;
}

.legend-table-row {
  display: flex;
  align-items: center;
  line-height: 30px;
  border-bottom: 1px solid #ccc;
  padding: 0 5px 0 2px;
  cursor: pointer;
}

.legend-table-row > div {
  display: flex;
  align-items: center;
  width: 100%;
}

.legend-table-row:hover,
.legend-table-row:hover > div,
.legend-table-row.hover,
.legend-table-row > div.hover {
  background-color: #DAEBF8;
}

.legend-table-row.header {
  font-weight: bold;
  font-size: 12px;
}

.legend-table-row div.flex {
  flex: 1;
}

.legend-table-row .swatch {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid #707070;
}

.legend-table-row.header .swatch {
  border-color: transparent;
}

.legend-table-row .phase {
  width: 50px;
}

.legend-table-row .swatch.chronic-phase1 {
  background-color: $phase-1-chronic-fill;
}

.legend-table-row .swatch.chronic-phase2 {
  background-color: $phase-2-chronic-fill;
}

.legend-table-row .swatch.chronic-phase3 {
  background-color: $phase-3-chronic-fill;
}

.legend-table-row .swatch.chronic-phase4 {
  background-color: $phase-4-chronic-fill;
}

.legend-table-row .swatch.phase1 {
  background-color: $phase-1-fill;
}

.legend-table-row .swatch.phase2 {
  background-color: $phase-2-fill;
}

.legend-table-row .swatch.phase3 {
  background-color: $phase-3-fill;
}

.legend-table-row .swatch.phase4 {
  background-color: $phase-4-fill;
}

.legend-table-row .swatch.phase5 {
  background-color: $phase-5-fill;
}

.legend-table-row .swatch.no-evidence, .legend-table-row .swatch.chronic-no-evidence  {
  background-color: rgb(176,176,176);
}

.legend-table-row .swatch.chronic-no-data, .legend-table-row .swatch.no-data {
  background-color: white;
}

.export .legend-table-row div {
  white-space: nowrap;
}