@import '../../../../variables';

.atlas-dropdown {
  position: absolute;
  top: 10px;
  left: 75px;
  z-index: 500;
  width: 170px;
  font-size: 14px;
}

.atlas-dropdown__header {
  height: 38px;
  // width: 100%;
  
  display: flex;
  align-items: center;
  cursor: pointer;
}

.atlas-dropdown__list {
  margin-top: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  max-height: 300px;
}

.atlas-dropdown__list-inner {
  max-height: 300px;
  overflow-y: auto;
}

.atlas-dropdown__header,
.atlas-dropdown__list {
  background: white;
  border-radius: 5px;
  border: 1px solid #999999;
  box-shadow: 0px 0px 5px rgba(153, 153, 153, 0.5);
}

.atlas-dropdown__header,
.atlas-dropdown__list-item {
  padding-left: 15px;
  padding-right: 15px;
}

.atlas-dropdown__header {
  display: flex;
}

.atlas-dropdown__header span {
  flex: 1;
  margin: 0 5px;
}

.atlas-dropdown__list--closed {
  display: none;
}

.atlas-dropdown__list-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.atlas-dropdown__list-item:hover {
  background: $menu-blue;
}

.atlas-dropdown__list-item.no-result,
.atlas-dropdown__list-item.no-result:hover {
  font-style: italic;
  color: #999;
  background: white;
}

.atlas-dropdown__input {
  width: 100%;
  margin-bottom: 10px;
  border: none;
  font-size: 14px;
  margin-right: 5px;
}

.atlas-dropdown__input:focus {
  outline: none;
}

.atlas-dropdown__input-row {
  display: flex;
  padding: 0 15px;
}

.atlas-dropdown__input-icon {
  color: #999;
}