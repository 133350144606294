.limiting-factors-graphic {
  position: relative;
  padding: 15px 0;
}

.limiting-factors.no-data {
  padding: 10px 0;
}

.limiting-factor-labels {
  display: flex;
  justify-content: space-evenly;
  margin-left: 60px;
  width: 230px;
  height: 40px;
}

.limiting-factor-labels div {
  width: 70px;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
}

.limiting-factor-bars {
  display: flex;
  height: 160px;
  position: relative;
  justify-content: space-evenly;
  margin-left: 60px;
  width: 230px;
}

.limiting-factor-bar {
  height: 100%;
  position: relative;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.limiting-factor-segment {
  width: 98%;
  transition: height .4s;
}

.limiting-factors .none,
.limiting-factors .nota_limiting_factor,
.limiting-factors-graphic .none,
.limiting-factors-graphic .nota_limiting_factor,
.limiting-factors-legend .none ,
.limiting-factors-legend .nota_limiting_factor {
  background-color: #D7D7D7;
  border-color: #D7D7D7;
}

.limiting-factors .minor,
.limiting-factors .minor_limiting_factor,
.limiting-factors-graphic .minor,
.limiting-factors-graphic .minor_limiting_factor,
.limiting-factors-legend .minor, 
.limiting-factors-legend .minor_limiting_factor {
  background-color:#FFCDFE;
  border-color:#FFCDFE;
}

.limiting-factors .major,
.limiting-factors .major_limiting_factor,
.limiting-factors-graphic .major,
.limiting-factors-graphic .major_limiting_factor,
.limiting-factors-legend .major, 
.limiting-factors-legend .major_limiting_factor {
  background-color:#FD38CD;
  border-color: #FD38CD;
}

.limiting-factors .extreme,
.limiting-factors .extreme_limiting_factor,
.limiting-factors-graphic .extreme,
.limiting-factors-graphic .extreme_limiting_factor,
.limiting-factors-legend .extreme,
.limiting-factors-legend .extreme_limiting_factor {
  background-color: #FF096C ;
  border-color: #FF096C ;
}

.limiting-factor-segment.hover {
  border-color: black;
  border-width: 2px;
  border-style: solid;
  margin-top: -2px;
  margin-left: -2px;
}

.limiting-factors-graphic svg {
  position: absolute;
  width: 250px;
  height: 190px;
  margin-left: 40px;
  top: 40px;
}

.limiting-factors-graphic svg line {
  stroke: #999;
}

.limiting-factors-graphic .domain {
  display: none;
}

.limiting-factors-y {
  position: absolute;
  transform: rotate(-90deg);
  left: -20px;
  top: 130px;
  font-size: 12px;
}

.limiting-factors-legend {
  font-size: 12px;
  /** display: flex;
  flex-direction: column;
  flex-wrap: wrap; **/
  margin-left: 22px;
  margin-top: 0px;
  min-height: 30px;
  padding-bottom: 15px;
}

.content__split-cols .export.limiting-factors-legend {
  max-width: 300px;
}

.limiting-factors-legend > div {
  width: 150px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  white-space: nowrap;
}

.limiting-factors-legend div.swatch {
  width: 10px;
  height: 10px;
  border: 1px solid #ccc;
  margin-right: 5px;
}

.limiting-factors .legend-table .swatch,
.limiting-factors-legend .legend-table .swatch {
  border-color: #999;
}

.limiting-factors .legend-table .factor,
.limiting-factors-legend .legend-table .factor {
  width: 120px;
}

.limiting-factors-graphic.export .limiting-factors-y {
  white-space: nowrap;
}

.limiting-factors .legend-table {
  padding: 8px;
}