.atlas-legend {
  background-color: white;
  font-size: 12px;
  line-height: initial;
  transition: right 0.4s, padding 0.4s, height 0.4s;
  padding: 0px 0px 0px 10px;
  clear: both;
  // height: calc(100% - 100px);
    // overflow: auto;
}

@media only screen and (max-height: 600px) {
  .public .atlas-legend {
    // height: calc(100% - 150px);
    margin-right: -10px;
  }
}

.atlas-legend.client {
  width: 300px;
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 500;
}

.public .atlas-legend {
  margin-left: 10px;
  border-left: 1px solid #ddd;
  position: relative;
}
.public .atlas-legend.active {
  display: block;
}
.public .content__split-cols .atlas-legend {
  margin-top: 20px;
  position: absolute;
  right: 0;
  overflow: auto;
}

.public .atlas-legend__top {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
@media only screen and (max-width: 500px) {
  .public .atlas-legend__top {
    margin-top: 10px;
  }
}
.atlas-legend.bottom {
  height: 0;
  padding: 0;
}
.atlas-legend.bottom.active {
  max-height: 290px;
  padding: 5px 0px;
}
.atlas-legend__section {
  margin-bottom: 5px;
}

.atlas-legend.bottom .atlas-legend__text,
.atlas-legend.bottom .atlas-legend__section--worse {
  white-space: nowrap;
}
.atlas-legend.bottom .atlas-legend__other,
.atlas-legend.export.public .atlas-legend__other {
  margin-left: 15px;
}
.atlas-legend.bottom .atlas-legend__other .atlas-legend__section:first-child {
  border-top: none;
}
.atlas-legend.bottom .atlas-legend__other .atlas-legend__section:first-child .atlas-legend__header,
.mapkey {
  /*border-bottom: 1px solid #ccc; */
  padding-bottom: 5px;
}

.public .atlas-legend__details-button,
.public .atlas-legend__key-button {
  padding: 5px 15px 0px;
  border: 1px solid transparent;
  color: #999;
  margin: 0;
  display: inline-block;
  margin-bottom: -1px;
}
.public .atlas-legend__details-button {
  margin-left: 15px;
}
.public .atlas-legend__details-button.active,
.public .atlas-legend__key-button.active {
  color: #125f9a;
  border-color: #ccc;
  border-bottom-color: #fff;
}
.atlas-legend__details-button svg,
.atlas-legend__key-button svg {
  margin-right: 10px;
}
.atlas-legend__inner,
.atlas-legend__details {
  max-height: 770px;
}
.atlas-legend__details {
  overflow-y: auto;
}
.atlas-legend__details__back {
  color: #125f9a;
  cursor: pointer;
  margin-bottom: 10px;
}
.atlas-legend__details__title {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 15px;
}
.atlas-legend__details__subtitle {
  font-weight: bold;
  margin: 5px 0 0 0;
}
.atlas-legend__details__prompt {
  font-style: italic;
}
.details .atlas-legend__inner {
  display: none;
}
.key .atlas-legend__inner,
.details .atlas-legend__details,
.export .atlas-legend__inner {
  display: block;
}
.export .atlas-legend__details {
  display: none;
}

.atlas-legend__section--worse svg { 
  margin-right: 10px; 
}  
.atlas-legend__header { 
  font-weight: bold; 
  margin-bottom: 5px; 
} 
.atlas-legend .legend-table-row { 
  border: none; 
  line-height: 16px; 
  align-items: flex-start; 
  margin-bottom: 3px; 
}  
.atlas-legend .legend-table-row .swatch { 
  margin-top: 2px; 
  width: 14px; 
  height: 14px; 
  margin-right: 15px; 
}  
.atlas-legend .triangle { 
  margin-top: 2px; 
  margin-right: 15px; 
  width: 2px;
  height: 5px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 12px solid transparent;
  display: inline-block;
}
.atlas-legend .chronic-recurrence {
  border-bottom-color: #f7aa1b;
}
.atlas-legend__tooltip-container {
  min-height: 30px;
}
.atlas-legend .tooltip {
  position: relative;
  margin: 0 0 10px 0;
  width: 250px;
  min-width: 0;
  font-size: 10px;
  overflow: hidden;
}
.atlas-legend .tooltip__content {
  min-height: 30px;
}
.atlas-legend .tooltip-chart {
  margin-top: 10px;
}
.atlas-legend__bar-legend {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.atlas-legend__bar-legend__pct {
  border-left: 1px solid #999;
  height: 10px;
  margin-top: -13px;
  width: 0;
}
.atlas-legend__bar-legend__pct span {
  display: block;
  margin-top: 12px;
  margin-left: -1px;
  width: 50px;
}
.atlas-legend__bar-legend__pct:last-child span {
  text-align: right;
  margin-left: -49px;
}
.atlas-legend__key-drivers-container {
  padding: 15px;
}
.atlas-legend__key-drivers-container > div:first-child {
  margin-bottom: 10px;
}
.atlas-legend__details__content {
  min-height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.atlas-legend__details__content > * {
  margin-bottom: 15px;
}
.atlas-legend__details .notesLink {
  margin: 15px 0;
}
.atlas-legend__full-analysis-button {
  padding: 10px 0 !important;
  color: white;
  text-decoration: none;
}
.atlas-legend__full-analysis-button svg {
  margin-right: 10px;
}
.atlas-legend__full-analysis-button {
  margin: 10px 0;
}
.atlas-legend.export {
  position: relative;
  background-color: white;
}
.atlas-legend__map {
  background-color: white;
}
.atlas-legend__map--bottom {
  padding-top: 10px;
}
.atlas-legend-icon-row {
  padding-bottom: 4px;
  width: 100%;
}
.atlas-legend-icon {
  display: inline-block;
  margin-top: 2px;
  width: 14px !important;;
  height: 14px !important;;
  margin-right: 5px;
}
.atlas-legend-icon-rfg {
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
}
.atlas-legend-icon-text {
  padding-left: 10px;
  padding-bottom: 5px;
}
.atlas-legend.export .atlas-legend__inner {
  padding: 15px;
}
.atlas-legend.export .atlas-legend__top {
  display: none;
}
.atlas-legend.export.public {
  width: 850px !important;
  height: 280px !important;
}
.atlas-legend.export.public .legend-table-row {
  display: inline-flex;
  margin-right: 10px;
  white-space: nowrap;
}
.atlas-legend.export.public .atlas-legend__map--bottom .legend-table-row {
  white-space: nowrap;
}
.atlas-legend.export.public .atlas-legend__inner {
  flex-direction: column;
}
.atlas-legend.export.public .atlas-legend__other {
  margin-left: 0;
}
.atlas-legend.export.public .atlas-legend__other .atlas-legend__section {
  margin-bottom: 0;
  margin-right: 20px;
  padding: 0 5px;
  display: inline-block;
  vertical-align: top;
}
.atlas-legend.export.public .atlas-legend__section--worse > * {
  display: inline;
}
.public-export .atlas-legend__inner {
  width: 100%;
}
.public-export .atlas-legend__other {
  display: none !important;
}
.atlas-legend__close {
  font-size: 18px;
  padding: 0 10px;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 30%;
  left: -15px;
  border-radius: 50% !important;
  margin-left: -20px !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
