@import './../../variables';

.tooltip {
  min-width: 200px;
  max-width: 300px;
  position: fixed;
  background: #ffffff;
  pointer-events: none;
  border: 1px solid #dddddd;
  border-radius: 0px !important;
  box-shadow: 2px 2px 8px rgba(0.1, 0.1, 0.1, 0.1) !important;
  z-index: 5000;
  margin: 15px;
  opacity: 1 !important;
}

.tooltip--persistent {
  position: relative;
  margin: 0;
  height: 105px;
  pointer-events: all;
  cursor: grab;
  border: none;
  left: -5px !important;
  top: -4px !important;
}

.tooltip--persistent.top {
  top: -100px !important;
}

.tooltip--persistent.left {
  left: -245px !important;
}

.tooltip--persistent.left .tooltip__close-button {
  left: -15px;
}

.tooltip--persistent.left .tooltip__phase {
  float: left;
}

.tooltip--persistent.left .tooltip__inner {
  margin-left: 15px;
}

.tooltip--persistent .tooltip__content {
  display: block;
}

.tooltip--persistent .tooltip__phase {
  height: 104px;
  float: right;
}

.tooltip__content {
  display: flex;
}

.tooltip__phase {
  width: 12px;
  border-right: 1px solid #ccc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.tooltip__phase.chronic-phase0 {
  display: none;
}

.tooltip__phase.chronic-phase1 {
  background-color: $phase-1-chronic-fill;
}

.tooltip__phase.chronic-phase2 {
  background-color: $phase-2-chronic-fill;
}

.tooltip__phase.chronic-phase3 {
  background-color: $phase-3-chronic-fill;
}

.tooltip__phase.chronic-phase4 {
  background-color: $phase-4-chronic-fill;
}

.tooltip__phase.chronic-phase9 {
  background-color: $phase-9-chronic-fill;
}

.tooltip__phase.phase0 {
  display: none;
}

.tooltip__phase.phase1 {
  background-color: $phase-1-fill;
}

.tooltip__phase.phase2 {
  background-color: $phase-2-fill;
}

.tooltip__phase.phase3 {
  background-color: $phase-3-fill;
}

.tooltip__phase.phase4 {
  background-color: $phase-4-fill;
}

.tooltip__phase.phase5 {
  background-color: $phase-5-fill;
}

.tooltip__phase.phase0 {
  background-color: $phase-0-fill;
}

.tooltip__phase.phase9 {
  background-color: $phase-9-fill;
}
.tooltip__phase.phase6 {
  color: #333333;
  background: repeating-linear-gradient(
    145deg,
    #640000,
    #640000 3px,
    #cc7a7a 3px,
    #cc7a7a 4px
  ) !important;
}

.tooltip__inner {
  padding: 5px 10px;
}

.tooltip__date {
  font-weight: bold;
  margin-bottom: 10px;
}

.tooltip__date,
.tooltip__prompt,
.tooltip__text {
  font-size: 12px !important;
}

.export .tooltip__text {
  white-space: nowrap;
}

.tooltip__title {
  font-size: 13px;
  margin-bottom: 5px;
}
.tooltip__title.title-medium {
  font-size: 11px;
}

.tooltip__prompt {
  font-style: italic;
}

.tooltip__close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -17px;
  right: -17px;
  background-color: #666666;
  color: #eee;
  border: 4px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.export .tooltip__close-button {
  display: none;
}
.tooltip__anchor {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
  pointer-events: none;
}

.tooltip__handle {
  height: 12px;
  width: 5px;
  border-right: 1px solid white;
  border-left: 1px solid white;
  margin: 4px auto;
  position: relative;
  z-index: 99;
}
