.conditionFilter{
  /*float: right;*/
  /*height: 100%;*/
  /*align-items: center;*/
  font-size: 1.5em;
  margin: 5px 2px 5px;
  height: 30px;
}

.conditionFilter input[type=radio]:hover, .conditionFilter label:hover {
    cursor: pointer !important;
}