.atlasStyleRadio label{
  font-size: 1.4em;
  margin-left: 5px;
}

.atlasStyleRadio .form-check-input{
  margin-top: 0.5rem;
}
.stylingGroup {
    text-align: left;
    margin: 0px 0px 10px;
    padding: 0px 0px 5px;
    border-bottom: 1px dotted rgba(26,28,34,.3);
    font-size: 1.4rem;
}

.stylingGroup h3 {
  font-size: 1.4rem;
}
h4.form-label {
  font-size: 1.3rem;
}
