@import '../../../../variables';

.iconMarker{
  width: 20px;
  height: 20px;
  background-size: contain;
}

.iconMarker .probe{
  display: none;
  background-color: white;
  border: 1px solid #666;
  padding: 5px 10px;
  white-space: nowrap;
  position: absolute;
  margin: 10px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.25);
  color: black;
}

.iconMarker.idp{
  width: 50px;
  height: 50px;
}

.iconMarker.phase1 {
  color: $phase-1-fill;
}

.iconMarker.phase2 {
  color: $phase-2-fill;
}

.iconMarker.phase3 {
  color: $phase-3-fill;
}

.iconMarker.phase4 {
  color: $phase-4-fill;
}

.iconMarker.phase5 {
  color: $phase-5-fill;
}

.iconMarker:hover .probe{
  display: block;
}