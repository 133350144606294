.mapboxgl-ctrl-zoom-reset{
  margin-top: 70px;
  font-size: 1.25em;
}

.mapboxgl-ctrl-zoom-reset.text{
  width: auto;
  padding: 0 10px;
}

#button-tooltip{
  background: none;
  border: none;
  font-size: 1.25em;
}

#button-tooltip .tooltip-inner{
  padding-left: 0;
  padding-right: 0;
  background-color: rgba(0,0,0,0.75);
}