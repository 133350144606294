.insetContainer{
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
}

.insetContainer.visible{
  border: 3px solid #ccc;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.insetContainer.visible.ne, .insetContainer.visible.nw{
  margin-top: 10px;
}

@media only screen and (max-width: 500px) {
    .insetContainer.visible.se, .insetContainer.visible.sw{
      bottom: 25% !important;
    }
}

.insetContainer .mapboxgl-ctrl-logo{
  display: none !important;
}

.insetContainer.visible.ne .mapboxgl-ctrl, .insetContainer.visible.nw .mapboxgl-ctrl{
  top: 10px !important;
}

.insetContainer.visible .mapboxgl-ctrl-icon{
  margin-top: 0;
}