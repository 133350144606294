.atlas-container-wrapper { 
  height: 730px;
}
.atlas-container {
  height: 600px;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .atlas-container {
    height: 300px !important;
  }
  .atlas-container-wrapper {
    height: 350px !important;
  }
}
.map-bottom-disclaimer{
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: white;
  z-index: 500;
}