.footer {
  max-height: 175px;
  display: flex;
  /*align-items: center;*/
  padding:20px 20px 30px;
  flex-wrap: nowrap;
}

.public .footer {
  max-height: 70px;
  overflow: hidden;
  padding-top: 15px;
  align-items: flex-start;
}
.footer .partners-and-co-authoring {
    text-align: left;
    padding-bottom: 10px;
    font-size: 12px;
    width: 50%;
}
.footer .global-partners {
    text-align: left;
    padding-left: 20px;
}
