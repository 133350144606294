@import '../../../variables';

span.header {
  font-weight: bold;
}
.phaseFilter {
  margin: 5px 2px 5px;
}
.phase-filter {
  opacity: 0.3;
  padding: 5px;
  font-size: 10px;
  cursor: pointer;
  margin: 2px;
}

.phase-filter.active-filter {
  opacity: 1;
}

.phase-filter.phase1 {
  background-color: $phase-1-fill;
  border-color: $phase-1-fill;
}
.phase-filter.phase2 {
  background-color: $phase-2-fill;
  border-color: $phase-2-fill;
}
.phase-filter.phase3 {
  background-color: $phase-3-fill;
  border-color: $phase-3-fill;
  color: #333;
}
.phase-filter.phase4 {
  background-color: $phase-4-fill;
  border-color: $phase-4-fill;
  color: #ccc;
}
.phase-filter.phase5 {
  background-color: $phase-5-fill;
  border-color: $phase-5-fill;
  color: white;
}

.chronic .phase-filter.phase1 {
  background-color: $phase-1-chronic-fill;
  border-color: $phase-1-chronic-fill;
}
.chronic .phase-filter.phase2 {
  background-color: $phase-2-chronic-fill;
  border-color: $phase-2-chronic-fill;
}
.chronic .phase-filter.phase3 {
  background-color: $phase-3-chronic-fill;
  border-color: $phase-3-chronic-fill;
}
.chronic .phase-filter.phase4 {
  background-color: $phase-4-chronic-fill;
  border-color: $phase-4-chronic-fill;
}
