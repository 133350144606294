@import './../../../variables';

.chronic-phase {
  display: flex;
  padding: 10px 0;
  align-items: center;
}

.public .chronic-phase {
  padding: 0;
  display: block;
  margin: 0;
}

.chronic-phase-chart {
  height: 130px;
  margin: 10px 0px;
  text-align: center;
}

.chronic-phase-chart--split {
  height: 130px;
}

.chronic-phase__legend {
  flex: 1;
}

.content__split-cols .export.chronic-phase__legend {
  max-width: 240px;
}

.donut-segment {
  cursor: pointer;
}

.donut-segment.chronic-phase1 {
  fill: $phase-1-chronic-fill;
}

.donut-segment.chronic-phase2 {
  fill: $phase-2-chronic-fill;
}

.donut-segment.chronic-phase3 {
  fill: $phase-3-chronic-fill;
}

.donut-segment.chronic-phase4 {
  fill: $phase-4-chronic-fill;
}

.donut-segment.phase9 {
  fill: $phase-9-chronic-fill;
}
.donut-segment.hover {
  stroke: #000;
}

.donut-segment.zero {
  stroke: none;
}

.chronic-phase .legend-table {
  flex: 1;
  margin-right: 10px;
}
.legend-table .header-row {
  font-size: 12px;
}


.legend-table .swatch {
  margin-right: 3px !important;
}

.chronic-phase__graphic.export, .chronic-phase__graphic.export .chronic-phase-chart{
  height: 300px !important;
  width: 300px !important;
}

.chronic-phase__graphic.export .chronic-phase-chart {
  transform: scale(2);
  transform-origin: 0 0;
  margin: 0;
}