.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.loader__text {
  margin-top: 5px;
  font-size: 18px;
}

.loader__icon {
  font-size: 40px;
}