.atlasStylingContainer{
  display: none;
}

.atlasStylingContainer.active{
  display: block;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 20px;
}

.atlas-style__toggle {
  font-size: 18px; 
  padding: 0 10px;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 32%;
  left: 25px;
  border-radius: 50% !important;
  margin-left: -20px !important;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.25);
  z-index: 1000;
} 
.atlasStylingContainer + .atlas-style__toggle{
  z-index: 3;
  left: 2%;
}
.atlasStylingContainer.active + .atlas-style__toggle{
  left: 203px;
}
.atlasStyling{
  padding: 0 5px;
}

.atlasStyling .col{
  padding: 0 15px
}
