@import '../../../../variables';

.tooltip-chart {
  margin: 10px 0;
  border: 1px solid #999;
  font-size: 12px;
}

.tooltip-chart__bars {
  display: flex;
}

.tooltip-chart__bar--phase1 {
  background: $phase-1-fill;
}

.tooltip-chart__bar--phase2 {
  background: $phase-2-fill;
}

.tooltip-chart__bar--phase3 {
  background: $phase-3-fill;
}

.tooltip-chart__bar--phase4 {
  background: $phase-4-fill;
}

.tooltip-chart__bar--phase5 {
  background: $phase-5-fill;
}

.tooltip-chart__bar--chronic-phase1 {
  background: $phase-1-chronic-fill;
}

.tooltip-chart__bar--chronic-phase2 {
  background: $phase-2-chronic-fill;
}

.tooltip-chart__bar--chronic-phase3 {
  background: $phase-3-chronic-fill;
}

.tooltip-chart__bar--chronic-phase4 {
  background: $phase-4-chronic-fill;
}