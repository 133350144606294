.layout-box-toggle {
  display: flex;
  font-size: 14px;
  margin: 0 20px;
  width: 80px;
  justify-content: space-between;
  align-items: center;
}

.layout-box-toggle__icon {
  font-size: 16px;
  cursor: pointer;
  margin-top: 2px;
}

.export .layout-box-toggle {
  display: none;
}