.rangeslider-handle-wrapper {
    position: absolute;
    margin-left: -7px;
    margin-top: 5px;
    z-index: 2;
    width: 18px;
    height: 18px;
    border: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #125F9A;
}
.rangeslider-handle {
    margin-top: -20px;
} 
.rangeslider-track {
    position: absolute;
    height: 10px;
    z-index: 1;
    margin-top: 10px;
    background-color: #125F9A;
    border-radius: 5px;
    cursor: pointer;
} 

.rangeslider-track-top-tick {
    position: absolute;
    margin-top: 22px;
    width: 2px;
    height: 5px;
    background-color: #4F8DB7;
}
.rangeslider-track-top-tick:last-child {
    margin-left: -5px;
}
.rangeslider-track-bottom-tick {
    position: absolute;
    margin-top: 25px;
    text-align: center;
    font-size: 11px;
}