@import '../../variables';

.header {
  margin: 0px;
  font-size: 18px;
}

.header-title {
  font-weight: 700;
  font-size: 20px;
}

.header__logo {
  height: 80px;
  width: auto;
}

.header__top {
  height: 95px;
  background: $main-blue;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.header__menu {
  height: 54px;
  background: $main-blue;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.header__menu-right {
  display: flex;
}

.header__menu-report-button,
.header__menu-exit-report-button {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.header__button-text {
  margin-left: 5px;
}

.header__menu-exit-report-button {
  background-color: white;
  color: #4F8DB7;
}

.header__menu-exit-report-button svg {
  margin-left: 10px;
}

.header__menu-report-button:hover {
  background-color: $main-blue;
}

.header__menu-exit-report-button:hover {
  background-color: #eee;
}

.header__bottom {
  display: flex;
  padding: 15px 20px;
}

.header__bottom__section {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.header__bottom__section select {
  appearance: none;
  outline: none;
  border: 1px solid #999;
  border-radius: 0;
  background-color: white;
  padding: 5px 25px 5px 5px;
  margin-left: 10px;
  width: 200px;
}

.header__bottom__section select + svg {
  margin: 0 20px 0 -20px;
  pointer-events: none;
}

.sub-header {
  /*height: 60px;*/
  background: white;
  border-bottom: 1px solid #cfcfcf;
}

.sub-header__inner {
  display: flex;
  padding: 10px 20px;
  background-color: white;
  justify-content: space-between;
  align-items: center;
}

.sub-header__inner > div{
  display: flex;
  align-items: center;
}

.sub-header__button {
  padding: 5px 7px;
  opacity: .3;
  cursor: pointer;
  margin: 0 5px;
  border: 0px solid #fff;
  border-radius: 30px;
}

.sub-header__button span {
  margin-left: 5px;
}

.sub-header__button--active {
  opacity: 1;
}

.sub-header__button.chronic-phase1 {
  background-color: $phase-1-chronic-fill;
}

.sub-header__button.chronic-phase2 {
  background-color: $phase-2-chronic-fill;
}

.sub-header__button.chronic-phase3 {
  background-color: $phase-3-chronic-fill;
  color: #fff;
}

.sub-header__button.chronic-phase4 {
  background-color: $phase-4-chronic-fill;
  color: #fff;
}

.sub-header__button.phase1 {
  background-color: $phase-1-fill;
}

.sub-header__button.phase2 {
  background-color: $phase-2-fill;
}

.sub-header__button.phase3 {
  background-color: $phase-3-fill;
}

.sub-header__button.phase4 {
  background-color: $phase-4-fill;
}

.sub-header__button.phase5 {
  background-color: $phase-5-fill;
}

.sub-header__button.phase5,
.sub-header__button.phase4 {
  color: white;
}

.sub-header .analyses-block__projected-button {
  float: right;
}

.bold {
  font-weight: 700;
}