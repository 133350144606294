@import '../../../variables';

.analyses-menu {
  width: 230px;
  flex-shrink: 0;
  margin-right: 20px;
}

.analyses-menu__inner {
  width: 230px;
  height: $analyses-menu-height;
  overflow-y: scroll;
  background: white;
  margin-right: 20px;
  border: 1px solid #ccc;
}

.analyses-menu__row {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}

.analyses-menu__row:hover,
.analyses-menu__row--selected {
  background: $menu-blue;
}

.analyses-block__toggle-text {
  margin-right: 8px;
}