.mainContent{
  height: calc(100vh - 55px);
  position: relative;
}

.mainContent.singleAnalysis{
  height: calc(100vh - 200px);
}

.content {
  padding:10px 20px;
  background: #ffffff;
}

.content__cols {
  display: flex;
  position: relative;
}

.content__col-left {
  // flex: 2 1 0;
  flex: 1;
  margin-right: 10px;
  position: relative;
}

.content__col-right {
  // flex: 1 1 0;
  width: 320px;
  margin-left: 10px;
}

.content__split-cols {
  display: flex;
  position: relative;
}

.content__split-cols-left,
.content__split-cols-right {
  flex: 1 1 0;
  max-width: calc(50% - 10px);
}

.with-2nd-right {
  flex: 1 1 0;
  max-width: calc(33% - 10px);
}

.content__split-cols-left {
  margin-right: 10px;
}

.public .content__split-cols-left {
  margin-left: 15px;
}

.content__split-cols-right {
  margin-left: 10px;
}

.content__split-cols-right .layout-box__title-outer .export, 
.content__split-cols-right .layout-box__title-outer span .export, 
.content__split-cols-right .layout-box__title-outer, 
.content__split-cols-right .layout-box__title-outer span, 
.with-2nd-right .layout-box__title-outer, 
.with-2nd-right .layout-box__title-outer span,
.content__split-cols-left .layout-box__title-outer *,
.content__split-cols-right .layout-box__title-outer *,
.with-2nd-right .layout-box__title-outer * {
  font-size: 12px !important;
}

.content__area-description {
  font-size: 14px;
  margin-bottom: 15px;
}

.content__area-description__title {
  font-weight: bold;
  margin-bottom: 10px;
}

.public .content__col-left {
  display: flex;
}

.content__col-header {
  display: flex;
  height: 20px;
}

.content__col-header__title {
  flex: 1;
  font-weight: bold;
}

.content__pie-chart {
  min-height: 275px;
  padding-top: 0px;
}

.periodButtons button{
  margin: 5px 0px;
}

.periodButtons button svg{
  margin-right: 10px;
}

.content__col-view-analysis-button {
  cursor: pointer;
  height: 18px;
  padding-left: 20px;
  padding-right: 20px;
  background: #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

}

.content__col-view-analysis-button:hover {
  background: #BBBBBB;
}

.content__col-header__date {
  font-size: 12px;
  font-weight: bold;
  /** padding: 3px 18px; **/
  padding: 5px 0;
}

.content__split-cols .content__col-header__date {
  padding-left: 0;
}

.content__col__legend-toggle {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
}

.content__col-header__legend-toggle {
  margin: 0 20px 0 15px;
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  top: 50px;
  right: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  cursor: pointer;
}

.content__split-legend {
  position: absolute;
  right: 0;
  top: 0;
}

.public .content {
  padding: 0;
}

.public .content__col-left {
  margin-right: 0;
}

.public .content__split-cols-left,
.public .content__split-cols-right {
  width: auto;
}

.population-estimates-table {
  padding: 10px 0px;
  overflow: auto;
}

.no-data-chart, .estimate-pop-no-data-chart {
  padding: 10px 0px;
  font-style: italic;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.public .no-data-chart, .public .estimate-pop-no-data-chart {
  font-style: normal;
  text-align: left;
}

.show-more, a.show-more:not([href]) {
  color: #125F9A;
  cursor: pointer;
}

.hidden-map {
  overflow: hidden;
  height: 0;
  width: 0;
  position: fixed;
}
.large-star {
  line-height: 1.5em;
  font-size: 1.25em;
}