.stylingGroup{
  margin: 20px 0;
}

.stylingGroup h4{
  margin-top: 4px;
}

input.styleSlider{
  height: 100%;
}