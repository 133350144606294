@import '../../../variables';

.layout-box {
  margin-bottom: 20px;
  border-radius: 5px;
  position: relative;
}

.layout-box__title-outer {
  height: 40px;
  background: $main-blue;
  font-size: 14px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.layout-box__title-inner {
  padding-left: 18px;
  flex: 1;
  color: white;
  text-align: center;
}

.layout-box__title-inner span {
  font-weight: 300;
  display: block;
  font-size: 14px;
}

.layout-box__download-button {
  margin-right: 18px;
  cursor: pointer;
  display: none;
}

.layout-box__download-button--exportable{
  display: block;
}

.layout-box.export {
  background-color: white;
  max-width: auto !important;
  max-height: auto !important;
  margin: -10px 0 0 -10px;
}

.export .layout-box__download-button--exportable{
  display: none;
}

.layout-box__content {
  border: 2px solid #125F9A;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  overflow: auto;
}

.layout-box__content--projected {
  border: 2px solid $main-blue;
}

.layout-box__content--report-excluded {
  display: none;
}

.layout-box__title-outer--projected {
  // background: white;
  background: $other-blue;
  color: #FFFFFF;
  border-left: 2px solid $main-blue;
  border-top: 2px solid $main-blue;
  border-right: 2px solid $main-blue;
}

.layout-box__title-inner--projected {
  color: #FFFFFF;
}

.layout-box__close-button,
.layout-box__add-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -17px;
  left: -17px;
  background-color: #666666;
  color: #eee;
  border: 4px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.layout-box__close-button:hover {
  background-color: #333;
  color: white;
}

.layout-box__add-button {
  background-color: $main-blue;
  color: white;
}

.layout-box__title-outer--report {
  display: inline-flex;
  line-height: 50px;
  padding-right: 18px;
}

.layout-box__title-outer--report-excluded {
  display: flex;
  line-height: 50px;
  padding-right: 0;
  background-color: #eee;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.layout-box__title-inner--report-excluded {
  color: $main-blue;
}

.layout-box__add-text-button {
  cursor: pointer;
  font-size: 14px;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  background: #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: -5px;
}

.layout-box__add-text-button:hover {
  background-color: #bbb;
}

.layout-box__report-text {
  display: none;
}

.layout-box__report-text--report {
  display: block;
}

.layout-box__report-text textarea {
  width: calc(100% - 20px);
  -webkit-appearance: none;
  border: none;
  resize: vertical;
  background-color: #eee;
  box-sizing: border-box;
  margin: 10px;
  outline: none;
  padding: 10px;
  height: 80px;
}

.layout-box__plus {
  margin-right: 10px;
}

.layout-box__download-button--report{
  display: none;
}