.population-tracking-sheet-analysis-condition-period {
  margin-top: 7px;
}
.pop-figures-disclaimer-menu {
    min-width: 50rem;
    -moz-box-shadow: -4px 4px 15px -8px #000000; 
    -webkit-box-shadow: -4px 4px 15px -8px #000000; 
    box-shadow: -4px 4px 15px -8px #000000;
    border-radius: 0px;
    -webkit-radius: 0px;
    -moz-radius: 0px;
    background-color: #125F9A;
    border-color: #125F9A;
}
.pop-figures-disclaimer {
    padding: 0px 10px;
    font-size: 12px;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.pop-figures-disclaimer svg {
    font-size: 16px;
}