@import '../../../variables';

.tab {
  display: flex;
  flex-shrink: 0;
  height: 40px;
  align-items: center;
  background-color: #CCCCCC;
  margin: 0 10px;
  font-size: 14px;
  padding: 0 20px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  position: relative;
  cursor: pointer;
}

.tab:hover {
  background-color: #bbb;
}

.tab__content {
  display: flex;
  max-width: 80px;
}

.tab__content span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tab--active,
.tab--active:hover {
  background-color: white;
  border-top: 6px solid $main-blue;
}

.tab__flag {
  display: none;
  margin-right: 10px;
}

.tab__plus {
  display: none;
  margin-right: 10px;
}

.tab--country .tab__flag,
.tab--add .tab__plus {
  display: block;
}

.tab__close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -17px;
  left: -17px;
  background-color: #666666;
  color: #eee;
  border: 4px solid #eee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tab__close-button:hover {
  background-color: #333;
  color: white;
}

.tab--country .tab__close-button,
.tab--more .tab__close-button,
.tab--add .tab__close-button {
  display: none;
}

.tab--add {
  border-radius: 3px;
  margin-top: -5px;
  align-self: flex-start;
}

.tab select {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.atlas-dropdown.tab__dropdown {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.atlas-dropdown.tab__dropdown .atlas-dropdown__list,
.atlas-dropdown.tab__dropdown .atlas-dropdown__list-inner {
  max-height: 200px;
  width: 170px;
}

.atlas-dropdown.tab__dropdown .atlas-dropdown__header {
  opacity: 0;
}