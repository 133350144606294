.key-drivers__legend {
  padding: 5px 8px;
}

.content__split-cols .export.key-drivers__legend {
  max-width: 240px;
}
.key-drivers__legend li {
  padding: 0 5px 0 2px;
  cursor: pointer;
  font-size: 12px;
  border: 2px solid transparent;
  border-bottom: 2px solid #ccc;
  margin: 4px 0;
}
.key-drivers__legend li.hover, .key-drivers__legend li:hover {
  border: 2px solid black;
}
.key-drivers__legend.export li {
  padding-top: 2px;
  padding-bottom: 2px;
}