.resizableContainer{
  position: relative;
}

.resizableContainer .handle{
  color: #333;
  text-shadow: 1px 1px white;
  font-size: 30px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  font-weight: bold;
  position: absolute;
  z-index: 99;
}

.handle.sw{
  bottom: 0;
  left: 0;
  cursor: sw-resize;
}
.handle.se{
  bottom: 0;
  right: 0;
  cursor: se-resize;
  text-align: right;
}
.handle.nw{
  top: -5px;
  left: 0;
  cursor: nw-resize;
}
.handle.ne{
  top: -5px;
  right: 0;
  cursor: ne-resize;
  text-align: right;
}

.resizableContainer:hover, .resizableContainer .handle:hover, .resizableContainer:focus, .resizableContainer .handle:focus {
  background: #DAEBF8;
  color: white;
}