@import '../../variables';

.report-tabs {
  display: flex;
  padding: 20px 20px 0 10px;
  box-sizing: border-box;
  width: 100%;
  align-items: flex-end;
}

.report-tabs-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 0 0;
}

.download-report-button {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 3px;
  background-color: $main-blue;
  color: white;
}

.download-report-button:hover {
  background-color: #333;
}

.download-report-button svg {
  margin-right: 10px;
}