@import '../../../variables';

.stacked-bar-wrapper {
  position: relative;
  width: calc(100% - 230px);
  /*// flex: 1 1 auto;*/
  flex-shrink: 1;
  padding-top: 5px;
}
.stacked-bar {
  position: relative;
}
.stacked-bar__content {
  height: $analyses-menu-height;
}

.stacked-bar__inner {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  background: white;
  height: $analyses-menu-height;
}

.stacked-bar__groups {
  /*// width: 100%;*/
  height: 100%;
  display: flex;
  align-items: flex-end;
  /*justify-content: center;*/
  justify-content: left;
}

.stacked-bar__bar-group {
  display: flex;
  flex-direction: column-reverse;
  border: 2px solid transparent;
  cursor: pointer;
  z-index: 1000;
}

.stacked-bar__bar-group--selected {
  border: 2px solid black;
}
.stacked-bar__bar {
  transition: height 500ms, background 500ms;
  border: 2px solid transparent;
}
.stacked-bar__bar:hover {
  border: 2px solid $main-blue;
}
.phase-shade {
  height: 5px;
}
.border-phase--p1, .stacked-bar__bar--p1 {
  background: $phase-1-fill;
}

.border-phase--p2, .stacked-bar__bar--p2 {
  background: $phase-2-fill;
}

.border-phase--p3, .stacked-bar__bar--p3 {
  background: $phase-3-fill;
}

.border-phase--p1, .stacked-bar__bar--p4 
{
  background: $phase-4-fill;
}

.border-phase--p1, .stacked-bar__bar--p5 {
  background: $phase-5-fill;
}

.border-phase--chronic-p1, .stacked-bar__bar--chronic-p1 {
  background: $phase-1-chronic-fill;
}

.border-phase--chronic-p2, .stacked-bar__bar--chronic-p2 {
  background: $phase-2-chronic-fill;
}

.border-phase--chronic-p3, .stacked-bar__bar--chronic-p3 {
  background: $phase-3-chronic-fill;
}

.border-phase--chronic-p4, .stacked-bar__bar--chronic-p4
{
  background: $phase-4-chronic-fill;
}

.stacked-bar__axis {
  stroke: #635F5D;
  stroke-width: .5;
}

.stacked-bar__tick-label {
  font-size: 12px;
}

.stacked-bar__y-outer {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  pointer-events: none;
}

.stacked-bar__y-text {
  font-size: 12px;  
  transform: rotate(-90deg);
  text-align: center;
}