@import '../../../variables';

.metadata__main {
  display: flex;
  height: 60px;
  font-size: 14px;
}

.metadata__name,
.metadata__population {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.metadata__name {
  background-color: #E4781F;
  text-transform: uppercase;
  color: white;
}

.metadata__name--chronic {
  background-color: $phase-4-chronic-fill;
}

.metadata__population {
  background-color: rgba(228, 120, 31, .1);
  color: #E4781F;
}
.metadata__population--chronic {
  background-color: $phase-2-chronic-fill;
  color: $phase-4-chronic-fill;
}

.metadata--projected .metadata--projected .metadata__population {
  background-color: rgba(228, 120, 31, .1);
  color: #E4781F;
  border: 2px solid  #E4781F;
}

.metadata--projected .metadata__name {
  background-color: rgba(251, 114, 0, 0.68);
  color: #FFFFFF;
  border: 2px solid  #E4781F;
}

.metadata--projected .metadata__population {
  border-left: none;
}

.metadata__population span {
  font-size: 24px;
  margin-right: 10px;
}

.with-2nd-right .metadata__main {
  /*font-size: 90%;*/
}
.with-2nd-right .metadata__population span {
  font-size: 90%;
}

.metadata__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metadata__confidence {
  margin: 8px 0;
  font-size: 12px;
}

.metadata__confidence span {
  margin: 0 10px;
}

.metadata__confidence svg,
.metadata__reset svg {
  margin: 0 3px;
}

.metadata__reset {
  font-size: 12px;
  color: $main-blue;
  cursor: pointer;
  font-weight: bold;
}

.metadata__reset:hover {
  color: black;
}