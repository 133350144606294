.no-data-screen {
  position: fixed;
  left: 0px;
  top: 55px;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: white;
}