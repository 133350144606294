.yearFilter{
  font-size: 1.5em;
  margin: 5px 2px 5px;
}

.yearFilter *{
  height: 30px;
  line-height: 30px;
}

.yearFilter input[type=checkbox]:hover{
    cursor: pointer !important;
}
.yearFilter input.yearNumber{
  width: 120px;
  text-align: center;
  height: 28px;
  color: #333;
  border: 1px solid #e9ecef;
}

.yearFilter button{
  padding: 0 10px;
}

.yearFilter .form-label{
  margin-right: 20px;
}