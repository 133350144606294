@import './../../../variables';

.phase-plus {
  padding: 10px 0;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  align-items: center;
  box-sizing: border-box;
  margin: 15px 0;
}
.public .phase-plus {
  padding: 0;
  margin: 0px 0px 15px;
}

.phase-bars {
  width: 130px !important;
  height: 34px;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
  white-space: nowrap;
  border-right: 2px solid transparent;
}

.phase-bars--split {
  width: 288px;
}

.public .phase-bars {
  width: 130px !important;
}

.phase-plus__legend {
  flex: 1;
}

.content__split-cols .export.phase-plus__legend {
  max-width: 200px;
}

.header-row {
  font-size: 12px;
}

.phase-bar {
  display: inline-block;
  height: 20px;
  transition: width .4s;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.phase-bars .phase-plus-line {
  position: absolute;
  height: 30px;
  width: 2px;
  top: -5px;
  margin-left: -1px;
  background-color: black;
  transition: left .4s;
}

.phase-bar.phase1 {
  background-color: #CEF9CE;
}

.phase-bar.phase2 {
  background-color: #F9E43C;
}

.phase-bar.phase3 {
  background-color: #E4781F;
  cursor: pointer;
}

.phase-bar.phase4 {
  background-color: #C60813;
  cursor: pointer;
}

.phase-bar.phase5 {
  background-color: #630205;
  cursor: pointer;
}

.phase-bars.hover,
.phase-bars.hover .phase3,
.phase-bars.hover .phase4,
.phase-bars.hover .phase5 {
  border-color: black;
}

.phase-bars.export {
  margin: 0;
  height: 44px !important;
  box-sizing: border-box;
}

.phase-bars.export .phase-bar {
  margin-top: 0;
  height: 28px;
}

.phase-bars.export .phase-bar:first-child {
  margin-left: -10px;
}

.chronic .atlas-layer--phase1,
.condition-C.atlas-layer--phase1 {
  fill: $phase-1-chronic-fill !important;
}

.chronic .atlas-layer--phase2,
.condition-C.atlas-layer--phase2 {
  fill: $phase-2-chronic-fill !important;
}

.chronic .atlas-layer--phase3,
.condition-C.atlas-layer--phase3 {
  fill: $phase-3-chronic-fill !important;
}

.chronic .atlas-layer--phase4,
.condition-C.atlas-layer--phase4 {
  fill: $phase-4-chronic-fill !important;
}

.chronic .tooltip__phase.phase1,
.condition-C .tooltip__phase.phase1 {
  background-color: $phase-1-chronic-fill !important;
}

.chronic .tooltip__phase.phase2,
.condition-C .tooltip__phase.phase2 {
  background-color: $phase-2-chronic-fill !important;
}

.chronic .tooltip__phase.phase3,
.condition-C .tooltip__phase.phase3 {
  background-color: $phase-3-chronic-fill !important;
}

.chronic .tooltip__phase.phase4,
.condition-C .tooltip__phase.phase4 {
  background-color: $phase-4-chronic-fill !important;
}

.chronic .legend-table-row .swatch.phase1,
.condition-C .legend-table-row .swatch.phase1 {
  background-color: $phase-1-chronic-fill !important;
}

.chronic .legend-table-row .swatch.phase2,
.condition-C .legend-table-row .swatch.phase2 {
  background-color: $phase-2-chronic-fill !important;
}

.chronic .legend-table-row .swatch.phase3,
.condition-C .legend-table-row .swatch.phase3 {
  background-color: $phase-3-chronic-fill !important;
}

.chronic .legend-table-row .swatch.phase4,
.condition-C .legend-table-row .swatch.phase4 {
  background-color: $phase-4-chronic-fill !important;
}

.chronic .tooltip-chart__bar--phase1,
.condition-C .tooltip-chart__bar--phase1 {
  background: $phase-1-chronic-fill !important;
}

.chronic .tooltip-chart__bar--phase2,
.condition-C .tooltip-chart__bar--phase2  {
  background: $phase-2-chronic-fill !important;
}

.chronic .tooltip-chart__bar--phase3,
.condition-C .tooltip-chart__bar--phase3  {
  background: $phase-3-chronic-fill !important;
}

.chronic .tooltip-chart__bar--phase4,
.condition-C .tooltip-chart__bar--phase4  {
  background: $phase-4-chronic-fill !important;
}


.chronic-phase-bars .phase-bar.phase1 {
  background-color: $phase-1-chronic-fill !important
}

.chronic-phase-bars .phase-bar.phase2 {
  background-color: $phase-2-chronic-fill !important;
}

.chronic-phase-bars .phase-bar.phase3 {
  background-color: $phase-3-chronic-fill !important;
  cursor: pointer;
}

.chronic-phase-bars .phase-bar.phase4 {
  background-color: $phase-4-chronic-fill !important;
}